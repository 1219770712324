export default function iconList() {
    const targets = document.querySelectorAll('.icon-list-wrapper .icon-list .icon-list-item')
    const icon = createIcon()
    if (!icon) return
    console.log(icon)
    targets.forEach(target => {
        target.appendChild(createIcon())
    })


    function createIcon() {
        const icon = document.querySelector('.icon-list-wrapper .icon-list-icon img')
        let iconSrc
        if (icon != null) {
            iconSrc = icon.src
        } else {
            return false
        }
        const iconEl = document.createElement('img')
        iconEl.src = iconSrc
        iconEl.className = 'icon'
        return iconEl
    }

}
