export default function menu() {

  const mainMenu = document.querySelector('.main-nav div.menu');
  const menuTriggers = document.querySelectorAll('.main-nav .burger');
  const menuIcons = document.querySelectorAll(
      '.main-nav .burger .material-icons');

  toggleSubMenu();

  menuTriggers.forEach(trigger => {
    trigger.addEventListener('click', () => {
      mainMenu.classList.toggle('closed');
      window.setTimeout(toggleIcons, 750);
    });
  });

  function toggleSubMenu() {
    const togglers = document.querySelectorAll('li[data-trigger]');

    togglers.forEach(t => {
      t.addEventListener('click', e => {
        const subEl = document.getElementById(t.dataset.trigger);
        if (!subEl.classList.contains('active')) {
          resetSubMenus();
        }
        subEl.classList.toggle('active');
        const img = t.querySelector('img');
        rotateArrow(img);
      });
    });

    function resetSubMenus() {
      togglers.forEach(t => {
        const subEl = document.getElementById(t.dataset.trigger);
        subEl.classList.remove('active');
      });
      document.querySelectorAll('.has-sub img').forEach(i => {
        i.classList.remove('rotate');
      });
    }

    function rotateArrow(img) {
      img.classList.toggle('rotate');
    }
  }

  function toggleIcons() {
    menuIcons.forEach(icon => {
      icon.classList.toggle('active');
    });
  }

  function setActiveItemDot(el) {
    el.classList.add('active');
  }
}
