import menu from './Components/menu';
import iconList from './Components/iconList';
import accordion from './Components/accordion';
import fontSize from './Components/fontSize';

require('./bootstrap');

menu();
iconList();
accordion();
fontSize();
