export default function accordion() {
    console.log('Accordion')
    const accordions = document.querySelectorAll('.accordion')
    console.log(accordions)

    accordions.forEach(accordion => {
        const items = accordion.querySelectorAll('.accordion-item')
        items.forEach(item => {
            const action = item.querySelector('.accordion-header')
            action.addEventListener('click', () => {
                if (!item.classList.contains('active'))
                    resetItems(items)
                item.classList.toggle('active')
            })
        })
        console.log(items)
    })

    function resetItems(items) {
        items.forEach(item => {
            item.classList.remove('active')
        })
    }


}
